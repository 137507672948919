import Vue from 'vue'
import VueRouter from 'vue-router'
// @ 指 src目录下
import Login from '@/views/login'
import User from '@/views/user'
import UserSetting from '@/views/user/UserSetting.vue'
import UserAdd from '@/views/user/UserAdd.vue'
import UserList from '@/views/user/UserList.vue'
import SetChild from '@/views/user/SetChild.vue'
import feedback_add from '@/views/user/feedback_add.vue'
import SignOut from '@/views/login/SignOut.vue'
import Dialog from '@/views/dialog/WxNew.vue'
import Dialog1 from '@/views/dialog/WxNew1.vue'
import Dialog2 from '@/views/dialog/WxNew2.vue'
import Dialog3 from '@/views/dialog/WxNew3.vue'
import Dialog4 from '@/views/dialog/WxNew4.vue'
import Dialog5 from '@/views/dialog/WxNew5.vue'
import Dialog6 from '@/views/dialog/WxNew6.vue'
import Dialog7 from '@/views/dialog/WxNew7.vue'
import smile from '@/views/dialog/smile.vue'
import ckfg from '@/views/dialog/ckfg.vue'
import dhnr from '@/views/dialog/dhnr.vue'
import dhjh from '@/views/dialog/dhjh.vue'
import fkjc from '@/views/dialog/fkjc.vue'
import ztsz from '@/views/dialog/ztsz.vue'
import aqsz from '@/views/dialog/aqsz.vue'
import qjsz from '@/views/dialog/qjsz.vue'
import install_show from '@/views/dialog/install_show.vue'
import install_show2 from '@/views/dialog/install_show2.vue'
import install_show3 from '@/views/dialog/install_show3.vue'
import install_show4 from '@/views/dialog/install_show4.vue'
import install_show5 from '@/views/dialog/install_show5.vue'
import liul from '@/views/user/liul.vue'
import debug_list from '@/views/user/debug_list.vue'
import debug_add from '@/views/user/debug_add.vue'
import debug_xg from '@/views/user/debug_xg.vue'
import liul_one from '@/views/user/liul_one.vue'
import DialogTest from '@/views/dialog/DialogTest.vue'
import Dzydy from '@/views/dialog/Dzydy.vue'
import DzydyINFO from '@/views/dialog/DzydyInfo.vue'
import toolsRouter from '@/router/tools.js'

Vue.use(VueRouter)
// 路由配置表
const routes = [{
    path: '/',
    redirect: '/login'
  },
  {
    path: '/dialog/test',
    name: 'DialogTest',
    component: DialogTest,
  },
  {
    path: '/liul/one',
    name: 'liul_one',
    component: liul_one,
  },
  {
    path: '/user/liul',
    name: 'liul',
    component: liul,
  },
  {
    path: '/debug/xg',
    name: 'debug_xg',
    component: debug_xg,
  },
  {
    path: '/debug/add',
    name: 'debug_add',
    component: debug_add,
  },
  {
    path: '/debug/list',
    name: 'debug_list',
    component: debug_list,
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/user',
    name: 'user_index',
    component: User
  },
  {
    path: '/user/setting',
    name: 'user_setting',
    component: UserSetting
  },
  {
    path: '/user/add',
    name: 'user_add',
    component: UserAdd
  },
  {
    path: '/user/list',
    name: 'user_list',
    component: UserList
  },
  {
    path: '/user/feedback_add',
    name: 'feedback_add',
    component: feedback_add
  },
  {
    path: '/user/setting/child/:account',
    name: 'set_child',
    component: SetChild
  },
  {
    path: '/sign/out',
    name: 'sign_out',
    component: SignOut
  },
  {
    path: '/dialog',
    name: 'dialog',
    component: Dialog
  },
  {
    path: '/dialog1',
    name: 'dialog1',
    component: Dialog1
  },
  {
    path: '/dialog2',
    name: 'dialog2',
    component: Dialog2
  },
  {
    path: '/dialog3',
    name: 'Dialog3',
    component: Dialog3
  },
  {
    path: '/dialog4',
    name: 'Dialog4',
    component: Dialog4
  },
  {
    path: '/dialog5',
    name: 'Dialog5',
    component: Dialog5
  },
  {
    path: '/dialog6',
    name: 'Dialog6',
    component: Dialog6
  },
  {
    path: '/dialog7',
    name: 'Dialog7',
    component: Dialog7,
    meta: {
      name: 'viewport',
      content: 'width=device-width, initial-scale=1.0;user-scalable=no'
    }
  },
  {
    path: '/dialog/ckfg',
    name: 'ckfg',
    component: ckfg
  },
  {
    path: '/dialog/dhnr',
    name: 'dhnr',
    component: dhnr
  },
  {
    path: '/dialog/dhjh',
    name: 'dhjh',
    component: dhjh
  },
  {
    path: '/dialog/fkjc',
    name: 'fkjc',
    component: fkjc
  },
  {
    path: '/dialog/ztsz',
    name: 'ztsz',
    component: ztsz
  },
  {
    path: '/dialog/aqsz',
    name: 'aqsz',
    component: aqsz
  },
  {
    path: '/dialog/qjsz',
    name: 'qjsz',
    component: qjsz
  },
  {
    path: '/dialog/install_show',
    name: 'install_show',
    component: install_show
  },
  {
    path: '/dialog/install_show2',
    name: 'install_show2',
    component: install_show2
  },
  {
    path: '/dialog/install_show3',
    name: 'install_show3',
    component: install_show3
  },
  {
    path: '/dialog/install_show4',
    name: 'install_show4',
    component: install_show4
  },
  {
    path: '/dialog/install_show5',
    name: 'install_show5',
    component: install_show5
  },
  {
    path: '/smile',
    name: 'smile',
    component: smile
  },
  {
    path: '/dialog/dzydy',
    name: 'DZYDY',
    component: Dzydy
  },
  {
    path: '/dialog/dzydy/info',
    name: 'DZYDYINFO',
    component: DzydyINFO
  },
  ...toolsRouter
]

const router = new VueRouter({
  routes
})

// 使用 router.beforeEach 注册一个全局前置守卫，判断用户是否登陆
router.beforeEach((to, from, next) => {
  if (to.path === '/login' || to.path === '/dialog' || to.path === '/smile' || to.path === '/dialog/test' || to.path === '/dialog1' || to.path === '/dialog2' || to.path === '/dialog3' || to.path === '/dialog4' || to.path === '/dialog5' || to.path === '/dialog6' || to.path === '/dialog7') {
    next();
  } else {
    let token = sessionStorage.getItem('user');
    console.log('---------- token -----------', token)
    if (token === null || token === '') {
      next('/login');
    } else {
      next();
    }
  }
});

export default router
