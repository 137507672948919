import { render, staticRenderFns } from "./install_show2.vue?vue&type=template&id=f2c5d6ea&scoped=true"
import script from "./install_show2.vue?vue&type=script&lang=js"
export * from "./install_show2.vue?vue&type=script&lang=js"
import style0 from "./install_show2.vue?vue&type=style&index=0&id=f2c5d6ea&prod&scoped=scoped&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/_vue-loader@15.11.1@vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f2c5d6ea",
  null
  
)

export default component.exports